import { useNavigate } from "react-router-dom";
import { footerData } from ".";
import { facebook, instagram, logo } from "../../images";
import { MaxContentWrapper } from "../max-content-wrapper/MaxContentWrapper";
import Typography from "../typography/Typography";
import ContactInfoIconBox from "./components/ContactInfoIconBox";
import {
  FooterWrapper,
  ContactInfoWrapper,
  ContactInfoIcons,
  SocialIcons,
  LogoImg,
  PolicyWrapper,
  CreatedBy,
} from "./style";

const Footer = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };
  return (
    <FooterWrapper>
      <MaxContentWrapper>
        <div>
          <LogoImg
            onClick={handleLogoClick}
            alt="logo"
            src={logo}
            loading="lazy"
          />
          <ContactInfoWrapper>
            <Typography fontSize="2rem">Contact information:</Typography>
            <ContactInfoIcons>
              {footerData.map((i) => {
                return (
                  <ContactInfoIconBox iconUrl={i.icon} content={i.content} />
                );
              })}
            </ContactInfoIcons>
          </ContactInfoWrapper>
        </div>
        <SocialIcons>
          <img alt="facebook" src={facebook} loading="lazy" />
          <img alt="instagram" src={instagram} loading="lazy" />
        </SocialIcons>
        <PolicyWrapper>
          <Typography fontSize="1.4rem">
            {"© 2024 Agency. All rights reserved."}
          </Typography>
          <CreatedBy href="https://www.linkedin.com/in/lasha-tatanashvili-8356112a7/">
            Created By Lasha Tatanashvili
          </CreatedBy>
        </PolicyWrapper>
      </MaxContentWrapper>
    </FooterWrapper>
  );
};

export default Footer;
