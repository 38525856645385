import styled from "styled-components";

export const FooterWrapper = styled.div`
  background-color: #a6a8af;
  padding: 5rem 0;
  margin-top: 5rem;
`;

export const ContactInfoWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: 1rem;
  width: 20rem;

  @media (min-width: 768px) {
    margin-left: unset;
  }
`;

export const ContactInfoIcons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SocialIcons = styled.div`
  float: right;
  margin-top: -2rem;
  display: flex;
  gap: 1rem;
  margin-right: 1rem;

  @media (min-width: 768px) {
    margin-right: unset;
  }
`;

export const LogoImg = styled.img`
  width: 20rem;
  cursor: pointer;
  margin-left: 1rem;

  @media (min-width: 768px) {
    margin-left: unset;
  }
`;

export const PolicyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #333;
  margin-top: 4rem;
  padding-top: 3rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
  @media (min-width: 768px) {
    padding-left: unset;
  }
`;

export const CreatedBy = styled.a`
  font-size: 1.4rem;
  text-decoration: none;
  color: #333;
  transform: color;
  transition-duration: 0.5s;

  &:hover {
    color: #127c15;
  }
`;
